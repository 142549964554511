import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { APPKEY } from '@libs/core/src/lib/core.shared';
import { Affair, MailModel } from '@libs/models/src';
import { MailService } from '@libs/services/src';
import { AffairConsteoService } from '@libs/services/src/lib/affair-consteo.service';
import { StringTool } from '_tools/string';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-affair-show-mails',
  templateUrl: './affair-show-mails.component.html',
  styleUrls: ['./affair-show-mails.component.scss'],
})
export class AffairShowMailsComponent implements OnInit, OnChanges {

  @Input() affair: Affair;
  public mailsData$: Observable<MailModel[]>;
  public consteoMailsData$: Observable<MailModel[]>;

  constructor(
    private mailService: MailService,
    private consteoService: AffairConsteoService,
    @Inject(APPKEY) public appkey: string,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.initList();
  }

  private initList() {
    this.mailsData$ = this.mailService.getByAffair(this.affair.id);
    this.consteoMailsData$ = this.consteoService.getMailsByAffairId(this.affair.id);
  }

  public extractLastPartOfPath(path: string): string {
    return StringTool.extractLastPartOfPath(path);
  }

}
