/* eslint-disable no-undef */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Agency } from '@libs/models/src';
import { AgencyService, UnsubscribeOnDestroy } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscriber, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgencyConsteoTokenComponent } from './agency-consteo-token/agency-consteo-token.component';

@Component({
  selector: 'app-agencies-index',
  templateUrl: './agencies-index.component.html',
  styleUrls: ['./agencies-index.component.scss'],
})
export class AgenciesIndexComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {

  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true,
    },
    {
      attr: 'ref',
      name: 'Réf',
      individualFilterInput: true,
    },
    {
      attr: 'name',
      name: 'Nom',
      individualFilterInput: true,
    },
    {
      attr: 'postalCode',
      name: 'CP',
      individualFilterInput: true,
    },
    {
      attr: 'locality',
      name: 'Ville',
      individualFilterInput: true,
    },
    {
      attr: 'idAbm',
      name: 'ABM',
      individualFilterInput: true,
    },
    {
      attr: 'idNrj',
      name: 'NRJ',
      individualFilterInput: true,
    },
    {
      attr: 'idPmb',
      name: 'PMB',
      individualFilterInput: true,
    },
    {
      attr: 'consteoToken',
      name: 'Token consteo',
      individualFilterInput: true,
    },
    {
      attr: 'createdAtFormatted',
      searchColumn: 'createdAt',
      name: 'Créée le',
      searchable: false,
    },
    {
      attr: 'updatedAtFormatted',
      name: 'Mis à jour le',
      searchColumn: 'updatedAt',
      searchable: false,
    },
    {
      attr: 'actions',
      name: 'Actions',
      searchable: false,
    },
  ];

  public datatableSelector = 'agencies-datatable';
  private agency$: Subscription;
  private agency: Agency = null;

  constructor(
    private titleService: Title,
    private agencyService: AgencyService,
    private modalService: NzModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des agences');
  }

  ngOnDestroy(): void {
    this.agency$?.unsubscribe();
    this.agencyService.agency$.next(null);
  }

  ngAfterViewInit(): void {
    this.agencyService.initDataTable(this.datatableSelector, this.columns);
    this.triggerDataTableEvents();
  }

  private triggerDataTableEvents() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', '.btn-token', function () {
      const id = $(this).data('id');
      that.agencyService.get(id)
        .pipe(takeUntil(that.isDestroyed$))
        .subscribe(
          (agency) => {
            that.agency = agency;
            that.openModalConsteoToken();
          },
        );
    });
  }

  private openModalConsteoToken() {
    const modalInstance = this.modalService.create({
      nzContent: AgencyConsteoTokenComponent,
      nzComponentParams: {
        agency: this.agency,
      },
    });
    modalInstance.afterClose.subscribe(
      () => {
        this.agencyService.dtInstance.draw(false);
      },
    );
  }

}
