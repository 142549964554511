<div *nzModalTitle>Modification du token Constéo</div>
<div class="d-flex justify-content-center">
  <button
    class="btn btn-primary btn-icon-left"
    (click)="generateRandomToken()">
    <i class="fas fa-key"></i>
    Générer un token aléatoire
  </button>
</div>
<form [formGroup]="form">
  <div class="form-group">
    <label>Token Constéo</label>
    <textarea
      type="text"
      class="form-control"
      formControlName="consteoToken"
      rows="3"></textarea>
  </div>
</form>
<div *nzModalFooter>
  <button
    class="btn btn-success btn-icon-left"
    [disabled]="isDisabled"
    (click)="onSaveClicked()">
    <i class="fas fa-save"></i>
    Mettre à jour
  </button>
</div>
