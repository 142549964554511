<div
  class="container"
  *ngIf="_boxed; else basicLayoutBlock">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>


<ng-template #contentTpl>
  <pg-sidebar>
    <ng-template #sideBarOverlay>
      <div class="row">
        <div class="col-xs-6 no-padding">
          <a
            href="javascript:void(0)"
            class="p-l-40">
            <img
              src="../../assets/img/demo/social_app.svg"
              alt="socail">
          </a>
        </div>
        <div class="col-xs-6 no-padding">
          <a
            href="javascript:void(0)"
            class="p-l-10">
            <img
              src="../../assets/img/demo/email_app.svg"
              alt="socail">
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 m-t-20 no-padding">
          <a
            href="javascript:void(0)"
            class="p-l-40">
            <img
              src="../../assets/img/demo/calendar_app.svg"
              alt="socail">
          </a>
        </div>
        <div class="col-xs-6 m-t-20 no-padding">
          <a
            href="javascript:void(0)"
            class="p-l-10">
            <img
              src="../../assets/img/demo/add_more.svg"
              alt="socail">
          </a>
        </div>
      </div>
    </ng-template>
    <ng-template #sideBarHeader>
      <img
        src="../../assets/img/logo_white.png"
        alt="logo"
        class="brand"
        pgRetina
        src1x="../../assets/img/logo_white.png"
        src2x="assets/img/logo_white_2x.png"
        width="78"
        height="22">
      <div class="sidebar-header-controls">
        <button
          type="button"
          class="btn btn-icon-link invert sidebar-slide-toggle m-l-20 m-r-10"
          [class.active]="_menuDrawerOpen"
          (click)="toggleMenuDrawer()">
          <i class="pg-icon">chevron_down</i>
        </button>
        <button
          type="button"
          class="btn btn-icon-link invert d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none"
          data-toggle-pin="sidebar"
          (click)="toggleMenuPin()">
          <i class="pg-icon"></i>
        </button>
      </div>
    </ng-template>
    <ng-template #menuItems>
      <pg-menu-items [Items]="menuLinks"></pg-menu-items>
    </ng-template>
  </pg-sidebar>
  <!-- PAGE CONTAINER -->
  <page-container>
    <pg-header [boxed]="_boxed">
      <!-- START MOBILE SIDEBAR TOGGLE -->
      <a
        href="javascript:void(0);"
        class="btn-icon-link toggle-sidebar d-lg-none"
        (click)="toggleMobileSidebar()">
        <i class="pg-icon">menu</i>
      </a>
      <!-- END MOBILE SIDEBAR TOGGLE -->
      <div class>
        <!-- START EMAIL MOBILE TOGGLE -->
        <a
          href="javascript:void(0);"
          class="toggle-secondary-sidebar align-items-center"
          (click)="toggleSecondarySideBar()"
          *ngIf="_layoutOption === 'email'">
          <span class="d-flex align-items-center">
            Inbox
            <span class="text-info">(12)</span>
            <span class="pg-icon">drop_down</span>
          </span>
        </a>
        <!-- END EMAIL MOBILE TOGGLE -->
        <div
          class="brand inline"
          [class.d-none]="_layoutOption === 'email'">
          <img
            src="../../assets/img/logo.png"
            alt="logo"
            pgRetina
            src1x="../../assets/img/logo.png"
            src2x="../../assets/img/logo_2x.png"
            width="78"
            height="22">
        </div>
      </div>
      <div class="d-flex align-items-center">
        <!-- START User Info -->
        <div
          class="dropdown pull-right d-lg-block d-none"
          dropdown>
          <button
            class="profile-dropdown-toggle"
            type="button"
            dropdownToggle
            id="profileDropdown"
            aria-label="profile dropdown">
            <span class="thumbnail-wrapper d32 circular inline">
              <img
                src="../../assets/img/profiles/avatar.jpg"
                alt
                pgRetina
                src1x="../../assets/img/profiles/avatar.jpg"
                src2x="../../assets/img/profiles/avatar_small2x.jpg"
                width="32"
                height="32">
            </span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right profile-dropdown"
            *dropdownMenu
            role="menu">
            <a
              href="#"
              class="dropdown-item"
              (click)="logout()">
              Se déconnecter
            </a>
          </div>
        </div>
        <!-- END User Info -->
      </div>
    </pg-header>
    <div class="page-content-wrapper {{_pageContainerClass}}">
      <!-- START PAGE CONTENT -->
      <div class="content {{_contentClass}}">
        <router-outlet></router-outlet>
      </div>
      <!-- END PAGE CONTENT -->
    </div>
  </page-container>
  <!-- QUICKVIEW -->
</ng-template>
