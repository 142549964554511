import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectModel, Setting, SubmitButton } from '@libs/models/src';
import { NotifService, SettingService, ThingService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings-production-settings-modal',
  templateUrl: './settings-production-settings-modal.component.html',
  styleUrls: ['./settings-production-settings-modal.component.scss'],
})
export class SettingsProductionSettingsModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() setting: Setting;
  @Input() availableEntitiesClasses: SelectModel[];
  @ViewChild('settingsAddEditSetting', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public items: any[];
  public entityClassesValues$: Subscription;
  public submitButton: SubmitButton;
  public fieldTypeValue = 'text';

  constructor(
    private settingService: SettingService,
    private thingService: ThingService,
    private modal: NzModalRef,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.form = this.settingService.getForm(this.setting, 'production');
    if (this.setting) {
      this.fieldTypeValue = this.setting.fieldType;
      this.thingService.getEntitiesClassesValues(this.setting.entityClass)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (entityClassesValues) => {
            this.items = entityClassesValues;
          },
        );
    }
    this.onChanges();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      this.fieldTypeValue = val.fieldType;
      this.thingService.getEntitiesClassesValues(val.entityClass)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(
          (entityClassesValues) => {
            this.items = entityClassesValues;
          },
        );
    });
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.setting) {
        this.settingService.update(this.setting.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (response) => {
              this.notifService.showSuccessNotif(response);
              this.modal.close();
            },
          );
      } else {
        this.settingService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(
            (response) => {
              this.notifService.showSuccessNotif(response);
              this.modal.close();
            },
          );
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.settingService.determinateSubmitButton(this.setting, 'le paramètre de production');
  }

  private isValidForm(): boolean {
    return this.form.valid;
  }

}
