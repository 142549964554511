<div *nzModalTitle>
  <h4>
    {{ profession ? 'Édition' : 'Ajout' }} d'un concurrent
  </h4>
</div>
<form
  [formGroup]="form"
  autocomplete="off">
  <div class="form-group">
    <label class="required">Nom</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="name">
  </div>
  <div class="form-group">
    <pg-switch
      [Size]="'small'"
      [Color]="'primary'"
      formControlName="isAbm">
    </pg-switch>
    <label class="ml-2">ABM</label>
  </div>
  <div class="form-group">
    <pg-switch
      [Size]="'small'"
      [Color]="'primary'"
      formControlName="isNrj">
    </pg-switch>
    <label class="ml-2">NRJ</label>
  </div>
  <div class="form-group">
    <pg-switch
      [Size]="'small'"
      [Color]="'primary'"
      formControlName="isPmb">
    </pg-switch>
    <label class="ml-2">PMB</label>
  </div>
  <div class="form-group">
    <pg-switch
      [Size]="'small'"
      [Color]="'primary'"
      formControlName="isAcc">
    </pg-switch>
    <label class="ml-2">ACC</label>
  </div>
</form>
<div *nzModalFooter>
  <submit-button
    [isValidForm]="isValidForm()"
    [submitButton]="submitButton"
    (onButtonClicked)="submitForm()">
  </submit-button>
</div>
