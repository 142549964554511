<div class="my-2 mx-3">
  <pgcard
    [Refresh]="false"
    [Toggle]="false"
    [Close]="false"
    [Maximize]="false"
    [HeaderClass]="'card-header-primary'"
    [HeaderIcon]="'fas fa-building'">
    <ng-template #CardTitle>Liste des types de marchés</ng-template>
    <div class="w-100 text-center">
      <button
        class="btn btn-success btn-lg btn-icon-left"
        (click)="onShowModalAddEditModal()">
        <i class="fas fa-plus"></i>
        <span>Ajouter un type de marché</span>
      </button>
    </div>
    <div class="row mx-2 my-3">
      <app-table-datatable-toggle-vis [columns]="columns"></app-table-datatable-toggle-vis>
    </div>
    <table-datatable
      [selector]="datatableSelector"
      [columns]="columns">
    </table-datatable>
  </pgcard>
</div>
