/* eslint-disable no-undef */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { CustomerTypeService } from '@libs/services/src/lib/customer-type.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CustomersTypesModalComponent } from '../customers-types-modal/customers-types-modal.component';

@Component({
  selector: 'app-customers-types-index',
  templateUrl: './customers-types-index.component.html',
  styleUrls: ['./customers-types-index.component.scss'],
})
export class CustomersTypesIndexComponent implements OnInit, AfterViewInit, OnDestroy {

  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true,
    },
    {
      attr: 'abbreviation',
      name: 'Abréviation',
      individualFilterInput: true,
    },
    {
      attr: 'name',
      name: 'Nom',
      individualFilterInput: true,
    },
    {
      attr: 'svgIcon',
      name: 'Îcone',
      searchable: false,
      orderable: false,
    },
    {
      attr: 'tllIcon',
      name: 'Îcone TLL',
      searchable: false,
      orderable: false,
    },
    {
      attr: 'actions',
      name: 'Actions',
      searchable: false,
      orderable: false,
    },
  ];

  public datatableSelector = 'customers-types-datatable';
  public customerType: any;
  public customerType$: Subscription;

  constructor(
    private titleService: Title,
    private customerTypeService: CustomerTypeService,
    private modalService: NzModalService,
    private swalService: SwalService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des types de clients');
  }

  ngAfterViewInit(): void {
    this.customerTypeService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy(): void {
    if (this.customerType$) {
      this.customerType$.unsubscribe();
    }
  }

  onShowModalAddEditModal(): void {
    this.form = this.customerTypeService.getForm(this.customerType);
    const modalInstance = this.modalService.create({
      nzContent: CustomersTypesModalComponent,
      nzComponentParams: {
        customerType: this.customerType,
        form: this.form,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.customerType = null;
    });
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        that.customerType$ = that.customerTypeService.get(id).subscribe(
          (data) => {
            if (data) {
              that.customerType = data;
              that.onShowModalAddEditModal();
            }
          },
        );
      }
    });
    $(`#${this.datatableSelector}`).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le type de client sera supprimé de manière irréversible.',
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.customerTypeService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              },
            );
          }
        });
      }
    });
  }

}
