/* eslint-disable no-undef */
import { Component, OnInit } from '@angular/core';
import { NotifService, SettingsDatatableParameters, SettingService, SwalService, ThingService, UnsubscribeOnDestroy } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SettingsProductionSettingsModalComponent } from '../settings-production-settings-modal/settings-production-settings-modal.component';

@Component({
  selector: 'app-settings-production-settings-index',
  templateUrl: './settings-production-settings-index.component.html',
  styleUrls: ['./settings-production-settings-index.component.scss'],
})
export class SettingsProductionSettingsIndexComponent extends UnsubscribeOnDestroy implements OnInit {

  public datatableSelector = 'settings-production-datatable';
  public setting: any = null;
  public setting$: Subscription;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
    },
    {
      attr: 'key',
      name: 'Clé',
    },
    {
      attr: 'description',
      name: 'Description',
    },
    {
      attr: 'fieldType',
      name: 'Type',
    },
    {
      attr: 'value',
      name: 'Valeur',
      orderable: false,
      searchable: false,
    },
    {
      attr: 'actions',
      name: 'Actions',
      orderable: false,
      searchable: false,
    },
  ];

  public availableEntitiesClasses = [];
  public availableEntitiesClasses$: Subscription;
  public param: SettingsDatatableParameters;
  private settingTypeKey = 'production';

  constructor(
    private settingService: SettingService,
    private swalService: SwalService,
    private thingService: ThingService,
    private modalService: NzModalService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initValues();
  }

  ngAfterViewInit(): void {
    this.settingService.initDataTable(this.datatableSelector, this.columns, this.settingTypeKey);
    this.initDataTableButtonsEvents();
  }

  onShowModalAddEdit(): void {
    this.modalService.create({
      nzContent: SettingsProductionSettingsModalComponent,
      nzComponentParams: {
        setting: this.setting,
        availableEntitiesClasses: this.availableEntitiesClasses,
      },
    });
  }

  initValues(): void {
    this.thingService.getAvailableEntitiesClasses('production')
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (availableEntitiesClasses) => {
          this.availableEntitiesClasses = availableEntitiesClasses;
        },
      );
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        that.settingService.get(id)
          .pipe(takeUntil(that.isDestroyed$))
          .subscribe(
            (setting) => {
              that.setting = setting;
              that.onShowModalAddEdit();
            },
          );
      }
    });
    $(`#${this.datatableSelector}`).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le paramètre sera supprimé de manière irréversible.',
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.settingService.delete(id)
              .pipe(takeUntil(that.isDestroyed$))
              .subscribe(
                (response) => {
                  that.notifService.showSuccessNotif(response);
                },
              );
          }
        });
      }
    });
  }

}
