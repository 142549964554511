import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ContactService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';

@Component({
  selector: 'app-contacts-index',
  templateUrl: './contacts-index.component.html',
  styleUrls: ['./contacts-index.component.scss'],
})
export class ContactsIndexComponent implements OnInit {

  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true,
    },
    {
      attr: 'lastname',
      name: 'Nom',
      individualFilterInput: true,
    },
    {
      attr: 'firstname',
      name: 'Prénom',
      individualFilterInput: true,
    },
    {
      attr: 'phone',
      name: 'Téléphone',
      individualFilterInput: true,
    },
    {
      attr: 'phoneSecondary',
      name: 'Téléphone secondaire',
      individualFilterInput: true,
    },
    {
      attr: 'email',
      name: 'Email',
      individualFilterInput: true,
    },
    {
      attr: 'emailSecondary',
      name: 'Email secondaire',
      individualFilterInput: true,
    },
    {
      attr: 'agenciesNames',
      name: 'Agences',
      searchable: false,
    },
    {
      attr: 'idAbm',
      name: 'ABM',
      individualFilterInput: true,
    },
    {
      attr: 'idNrj',
      name: 'NRJ',
      individualFilterInput: true,
    },
    {
      attr: 'idPmb',
      name: 'PMB',
      individualFilterInput: true,
    },
    {
      attr: 'idAcc',
      name: 'ACC',
      individualFilterInput: true,
    },
    {
      attr: 'createdAtFormatted',
      searchColumn: 'createdAt',
      name: 'Créé le',
      searchable: false,
    },
    {
      attr: 'updatedAtFormatted',
      name: 'Mis à jour le',
      searchColumn: 'updatedAt',
      searchable: false,
    },
  ];

  public datatableSelector = 'contacts-datatable';

  constructor(
    private titleService: Title,
    private contactService: ContactService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des contacts');
  }

  ngAfterViewInit(): void {
    this.contactService.initDataTable(this.datatableSelector, this.columns);
  }

}
