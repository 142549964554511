<div *nzModalTitle>
  <h4>
    {{ manager ? 'Édition' : 'Ajout' }} d'un responsable
  </h4>
</div>
<form
  [formGroup]="form"
  autocomplete="off">
  <div class="form-group">
    <label class="required">Nom</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="lastname">
  </div>
  <div class="form-group">
    <label class="required">Prénom</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="firstname">
  </div>
  <div class="form-group">
    <label class="required">Initiales</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="initials">
  </div>
  <div class="form-group">
    <label class="required">User ID</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="userId">
  </div>
  <div class="form-group">
    <label class="required">Erp source</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="erpSource">
  </div>
</form>
<div *nzModalFooter>
  <submit-button
    [isValidForm]="isValidForm()"
    [submitButton]="submitButton"
    (onButtonClicked)="submitForm()">
  </submit-button>
</div>
