/* eslint-disable no-undef */
import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ProfessionService, SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ProfessionsModalComponent } from '../professions-modal/professions-modal.component';

@Component({
  selector: 'app-professions-index',
  templateUrl: './professions-index.component.html',
  styleUrls: ['./professions-index.component.scss'],
})
export class ProfessionsIndexComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit, OnDestroy {

  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true,
    },
    {
      attr: 'name',
      name: 'Nom',
      individualFilterInput: true,
    },
    {
      attr: 'actions',
      name: 'Actions',
      searchable: false,
      orderable: false,
    },
  ];

  public datatableSelector = 'professions-datatable';
  public profession: any;
  private profession$: Subscription;

  constructor(
    private titleService: Title,
    private professionService: ProfessionService,
    private modalService: NzModalService,
    private swalService: SwalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des fonctions');
  }

  onShowModalAddEditModal(): void {
    this.form = this.professionService.getForm(this.profession);
    const modalInstance = this.modalService.create({
      nzContent: ProfessionsModalComponent,
      nzComponentParams: {
        profession: this.profession,
        form: this.form,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.profession = null;
    });
  }

  ngAfterViewInit(): void {
    this.professionService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  ngOnDestroy(): void {
    if (this.profession$) {
      this.profession$.unsubscribe();
    }
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        that.profession$ = that.professionService.get(id)
          .pipe(takeUntil(that.isDestroyed$))
          .subscribe(
            (data) => {
              that.profession = data;
              that.onShowModalAddEditModal();
            },
          );
      }
    });
    $(`#${this.datatableSelector}`).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'La fonction sera supprimée de manière irréversible.',
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.professionService.delete(id)
              .pipe(takeUntil(that.isDestroyed$))
              .subscribe(
                (success) => {
                  this.swalService.showSwalSuccess(success);
                },
              );
          }
        });
      }
    });
  }

}
