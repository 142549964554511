import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { SidebarComponent } from '@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from '@pages/components/quickview/quickview.component';
import { QuickviewService } from '@pages/components/quickview/quickview.service';
import { SearchOverlayComponent } from '@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from '@pages/components/header/header.component';
import { HorizontalMenuComponent } from '@pages/components/horizontal-menu/horizontal-menu.component';
import { pgListViewModule } from '@pages/components/list-view/list-view.module';
import { pgCardModule } from '@pages/components/card/card.module';
import { pgCardSocialModule } from '@pages/components/card-social/card-social.module';
import { RootLayout } from '@pages/layouts/root/root.component';
import { pgTabsModule } from '@pages/components/tabs/tabs.module';
import { pgTabLabelDirective } from '@pages/components/tabs/tab-label.directive';
import { QuillModule } from 'ngx-quill';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService, AuthModule } from '@eros-front/auth';
import { ApiModule } from '@eros-front/api';
import { CoreModule } from '@eros-front/core';
import { SharedModule } from '@libs/shared/src';
import { LayoutsModule } from '@libs/layouts/src';
import { APPKEY } from '@libs/core/src/lib/core.shared';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
// Shared Layout Components
import { DashboardComponent } from './dashboard/dashboard.component';

// Basic Bootstrap Modules

// Librairies
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AffairsIndexComponent } from './affairs/affairs-index/affairs-index.component';
import { GroupsIndexComponent } from './groups/groups-index/groups-index.component';
import { BrandsIndexComponent } from './brands/brands-index/brands-index.component';
import { AgenciesIndexComponent } from './agencies/agencies-index/agencies-index.component';
import { ContactsIndexComponent } from './contacts/contacts-index/contacts-index.component';
import { SettingsCustomerComponent } from './settings/settings-customer/settings-customer.component';
import { ProfessionsIndexComponent } from './settings/settings-customer/professions/professions-index/professions-index.component';
import { ProfessionsModalComponent } from './settings/settings-customer/professions/professions-modal/professions-modal.component';
import { MarketsTypesModalComponent } from './settings/settings-customer/markets-types/markets-types-modal/markets-types-modal.component';
import { MarketsTypesIndexComponent } from './settings/settings-customer/markets-types/markets-types-index/markets-types-index.component';
import { CustomersTypesIndexComponent } from './settings/settings-customer/customers-types/customers-types-index/customers-types-index.component';
import { CustomersTypesModalComponent } from './settings/settings-customer/customers-types/customers-types-modal/customers-types-modal.component';
import { IndicatorsCustomersIndexComponent } from './settings/settings-customer/indicators-customers/indicators-customers-index/indicators-customers-index.component';
import { IndicatorsCustomersModalComponent } from './settings/settings-customer/indicators-customers/indicators-customers-modal/indicators-customers-modal.component';
import { RivalsIndexComponent } from './settings/settings-customer/rivals/rivals-index/rivals-index.component';
import { RivalsModalComponent } from './settings/settings-customer/rivals/rivals-modal/rivals-modal.component';
import { ManagersIndexComponent } from './settings/settings-customer/managers/managers-index/managers-index.component';
import { ManagersModalComponent } from './settings/settings-customer/managers/managers-modal/managers-modal.component';
import { SettingsProductionSettingsIndexComponent } from './settings/settings-production/settings-production-settings/settings-production-settings-index/settings-production-settings-index.component';
import { SettingsProductionSettingsModalComponent } from './settings/settings-production/settings-production-settings/settings-production-settings-modal/settings-production-settings-modal.component';
import { SettingsProductionComponent } from './settings/settings-production/settings-production.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';
import { AgencyConsteoTokenComponent } from './agencies/agencies-index/agency-consteo-token/agency-consteo-token.component';

@NgModule({
  declarations: [
    AppComponent,
    AppWithSidebarComponent,
    DashboardComponent,
    AffairsIndexComponent,
    GroupsIndexComponent,
    BrandsIndexComponent,
    AgenciesIndexComponent,
    ContactsIndexComponent,
    SettingsCustomerComponent,
    ProfessionsIndexComponent,
    ProfessionsModalComponent,
    MarketsTypesModalComponent,
    MarketsTypesIndexComponent,
    CustomersTypesIndexComponent,
    CustomersTypesModalComponent,
    IndicatorsCustomersIndexComponent,
    IndicatorsCustomersModalComponent,
    RivalsIndexComponent,
    RivalsModalComponent,
    ManagersIndexComponent,
    ManagersModalComponent,
    SettingsProductionComponent,
    SettingsProductionSettingsIndexComponent,
    SettingsProductionSettingsModalComponent,
    AgencyConsteoTokenComponent,
    LoginLayoutComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    AuthModule.forRoot(environment.apiUrl),
    ApiModule.forRoot(environment.apiUrl),
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    LayoutsModule,
  ],
  providers: [
    { provide: APPKEY, useValue: environment.appKey },
    AuthGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
