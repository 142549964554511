import { DashboardComponent } from './dashboard/dashboard.component';
import { Routes } from '@angular/router';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AuthGuardService } from 'libs/auth/src/lib/auth-guard.service';
import { AffairsIndexComponent } from './affairs/affairs-index/affairs-index.component';
import { GroupsIndexComponent } from './groups/groups-index/groups-index.component';
import { BrandsIndexComponent } from './brands/brands-index/brands-index.component';
import { AgenciesIndexComponent } from './agencies/agencies-index/agencies-index.component';
import { ContactsIndexComponent } from './contacts/contacts-index/contacts-index.component';
import { SettingsCustomerComponent } from './settings/settings-customer/settings-customer.component';
import { SettingsProductionComponent } from './settings/settings-production/settings-production.component';
import { environment } from './../environments/environment';
import { SettingsAffairsComponent } from '@libs/settings/src/lib/settings-affairs/settings-affairs.component';
import { RedirectComponent } from '@libs/auth/src/lib/redirect/redirect.component';
import { LoginComponent } from '@libs/auth/src/lib/login/login.component';
import { LoginLayoutComponent } from './_layouts/login-layout/login-layout.component';

export const AppRoutes: Routes = [
  {
    path: 'login', component: LoginLayoutComponent, children: [
      {
        path: '',
        component: LoginComponent
      },
    ]
  },
  { path: 'redirect', component: RedirectComponent },
  {
    path: '',
    component: AppWithSidebarComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'affairs/index',
        component: AffairsIndexComponent
      },
      {
        path: 'groups/index',
        component: GroupsIndexComponent
      },
      {
        path: 'brands/index',
        component: BrandsIndexComponent
      },
      {
        path: 'agencies/index',
        component: AgenciesIndexComponent
      },
      {
        path: 'contacts/index',
        component: ContactsIndexComponent
      },
      {
        path: 'settings/affairs',
        component: SettingsAffairsComponent,
        data: { appKey: environment.appKey }
      },
      {
        path: 'settings/customer',
        component: SettingsCustomerComponent
      },
      {
        path: 'settings/production',
        component: SettingsProductionComponent
      }
    ],
  },
];
