import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { UnsubscribeOnDestroy } from '@libs/services/src';
import { CustomerTypeService } from '@libs/services/src/lib/customer-type.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customers-types-modal',
  templateUrl: './customers-types-modal.component.html',
  styleUrls: ['./customers-types-modal.component.scss'],
})
export class CustomersTypesModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() customerType: any;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private customerTypeService: CustomerTypeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.customerTypeService.setSubmitButton(this.submitButton);
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.customerType) {
        this.customerTypeService.update(this.customerType.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      } else {
        this.customerTypeService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      }
    }
  }

  private determinateSubmitButton(): void {
    this.submitButton = this.customerTypeService.determinateSubmitButton(this.customerType, 'le type de client');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
