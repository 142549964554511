import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SwalService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { IndicatorCustomerService } from '@libs/services/src/lib/indicator-customer.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import Swal from 'sweetalert2';
import { IndicatorsCustomersModalComponent } from '../indicators-customers-modal/indicators-customers-modal.component';

@Component({
  selector: 'app-indicators-customers-index',
  templateUrl: './indicators-customers-index.component.html',
  styleUrls: ['./indicators-customers-index.component.scss']
})
export class IndicatorsCustomersIndexComponent implements OnInit {
  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true
    },
    {
      attr: 'abbreviation',
      name: 'Abréviation',
      individualFilterInput: true
    },
    {
      attr: 'name',
      name: 'Nom',
      individualFilterInput: true
    },
    {
      attr: 'svgIcon',
      name: 'Îcone',
      searchable: false,
      orderable: false
    },
    {
      attr: 'tllIcon',
      name: 'Îcone TLL',
      searchable: false,
      orderable: false
    },
    {
      attr: 'actions',
      name: 'Actions',
      searchable: false,
      orderable: false
    },
  ];
  public datatableSelector = 'indicators-customers-datatable';
  public indicatorCustomer: any;
  public indicatorCustomer$: any;

  constructor(
    private titleService: Title,
    private indicatorCustomerService: IndicatorCustomerService,
    private modalService: NzModalService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Liste des indicateurs clients');
  }

  ngAfterViewInit() {
    this.indicatorCustomerService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }


  onShowModalAddEditModal() {
    this.form = this.indicatorCustomerService.getForm(this.indicatorCustomer);
    const modalInstance = this.modalService.create({
      nzContent: IndicatorsCustomersModalComponent,
      nzComponentParams: {
        indicatorCustomer: this.indicatorCustomer,
        form: this.form
      }
    })
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.indicatorCustomer = null;
    })
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $('#' + this.datatableSelector).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        that.indicatorCustomer$ = that.indicatorCustomerService.get(id).subscribe(
          (data) => {
            if (data) {
              that.indicatorCustomer = data;
              that.onShowModalAddEditModal();
            }
          }
        );
      }
    });
    $('#' + this.datatableSelector).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'L\'indicateur client sera supprimé de manière irréversible.'
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.indicatorCustomerService.delete(id).subscribe(
              (success) => {
                this.swalService.showSwalSuccess(success);
              }
            );
          }
        });
      }
    });
  }

}
