import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IndicatorCustomer, SubmitButton } from '@libs/models/src';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { IndicatorCustomerService } from '@libs/services/src/lib/indicator-customer.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-indicators-customers-modal',
  templateUrl: './indicators-customers-modal.component.html',
  styleUrls: ['./indicators-customers-modal.component.scss'],
})
export class IndicatorsCustomersModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() indicatorCustomer: IndicatorCustomer;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private indicatorCustomerService: IndicatorCustomerService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.indicatorCustomerService.setSubmitButton(this.submitButton);
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.indicatorCustomer) {
        this.indicatorCustomerService.update(this.indicatorCustomer.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      } else {
        this.indicatorCustomerService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      }
    }
  }

  private determinateSubmitButton(): void {
    this.submitButton = this.indicatorCustomerService.determinateSubmitButton(this.indicatorCustomer, 'l\'indicateur client');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
