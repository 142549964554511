<div class="my-4 mx-3">
  <pgcard
    [Refresh]="false"
    [Toggle]="true"
    [Close]="false"
    [Maximize]="false"
    [HeaderClass]="'card-header-primary'"
    [HeaderIcon]="'fas fa-hard-hat'">
    <form [formGroup]="form">
      <div class="form-group">
        <label class="required">Technicien</label>
        <ng-select
          [items]="techniciens"
          formControlName="userId">
        </ng-select>
      </div>
      <div class="form-group">
        <label class="required">Nombre de dossier</label>
        <input
          formControlName="number"
          class="form-control">
      </div>
      <div class="flex justify-content-center">
        <submit-button
          [isValidForm]="isValidForm()"
          [submitButton]="submitButton"
          (onButtonClicked)="onSubmit()">
        </submit-button>
      </div>
    </form>
  </pgcard>
</div>
