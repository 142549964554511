import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { RivalService } from '@libs/services/src/lib/rival.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rivals-modal',
  templateUrl: './rivals-modal.component.html',
  styleUrls: ['./rivals-modal.component.scss'],
})
export class RivalsModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() rival: any;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private rivalService: RivalService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.rivalService.setSubmitButton(this.submitButton);
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.rival) {
        this.rivalService.update(this.rival.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((response) => {
            this.notifService.showSuccessNotif(response);
            this.modal.close();
          });
      } else {
        this.rivalService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((response) => {
            this.notifService.showSuccessNotif(response);
            this.modal.close();
          });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.rivalService.determinateSubmitButton(this.rival, 'le concurrent');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
