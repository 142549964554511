<pgcard
  [Refresh]="false"
  [Toggle]="false"
  [Close]="false"
  [Maximize]="false"
  [HeaderClass]="'card-header-primary'"
  [HeaderIcon]="'fas fa-cog'">
  <ng-template #CardTitle>Liste des paramètres production</ng-template>
  <div class="w-100 text-center">
    <button
      class="btn btn-success btn-lg btn-icon-left"
      (click)="onShowModalAddEdit()">
      <i class="fas fa-plus"></i>
      <span>Ajouter un paramètre</span>
    </button>
  </div>
  <div class="mt-5">
    <table-datatable
      [selector]="datatableSelector"
      [columns]="columns">
    </table-datatable>
  </div>
</pgcard>
<app-settings-production-settings-modal
  [setting]="setting"
  [availableEntitiesClasses]="availableEntitiesClasses">
</app-settings-production-settings-modal>
