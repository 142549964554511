import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { ManagerService } from '@libs/services/src/lib/manager.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-managers-modal',
  templateUrl: './managers-modal.component.html',
  styleUrls: ['./managers-modal.component.scss'],
})
export class ManagersModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() manager: any;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private managerService: ManagerService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.managerService.setSubmitButton(this.submitButton);
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.manager) {
        this.managerService.update(this.manager.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((response) => {
            this.modal.close();
            this.notifService.showSuccessNotif(response);
          });
      } else {
        this.managerService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((response) => {
            this.modal.close();
            this.notifService.showSuccessNotif(response);
          });
      }
    }
  }

  private determinateSubmitButton(): void {
    this.submitButton = this.managerService.determinateSubmitButton(this.manager, 'le responsable');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
