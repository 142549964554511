/* eslint-disable no-undef */
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SubmitButton } from '@libs/models/src';
import { SwalService, UnsubscribeOnDestroy } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';
import { ManagerService } from '@libs/services/src/lib/manager.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ManagersModalComponent } from '../managers-modal/managers-modal.component';

@Component({
  selector: 'app-managers-index',
  templateUrl: './managers-index.component.html',
  styleUrls: ['./managers-index.component.scss'],
})
export class ManagersIndexComponent extends UnsubscribeOnDestroy implements OnInit {

  public form: FormGroup;
  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true,
    },
    {
      attr: 'lastname',
      name: 'Nom',
      individualFilterInput: true,
    },
    {
      attr: 'firstname',
      name: 'Prénom',
      individualFilterInput: true,
    },
    {
      attr: 'erpSource',
      name: 'Erp source',
      individualFilterInput: true,
    },
    {
      attr: 'actions',
      name: 'Actions',
      searchable: false,
      orderable: false,
    },
  ];

  public datatableSelector = 'managers-datatable';
  public manager: any;
  public manager$: Subscription;

  constructor(
    private titleService: Title,
    private managerService: ManagerService,
    private modalService: NzModalService,
    private swalService: SwalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des responsables');
  }

  ngAfterViewInit(): void {
    this.managerService.initDataTable(this.datatableSelector, this.columns);
    this.initDataTableButtonsEvents();
  }

  onShowModalAddEditModal(): void {
    this.form = this.managerService.getForm(this.manager);
    const modalInstance = this.modalService.create({
      nzContent: ManagersModalComponent,
      nzComponentParams: {
        manager: this.manager,
        form: this.form,
      },
    });
    modalInstance.afterClose.subscribe(() => {
      this.form = null;
      this.manager = null;
    });
  }

  private initDataTableButtonsEvents() {
    const that = this;
    $(`#${this.datatableSelector}`).on('click', 'button.btn-edit', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        that.manager$ = that.managerService.get(id)
          .pipe(takeUntil(that.isDestroyed$))
          .subscribe(
            (data) => {
              if (data) {
                that.manager = data;
                that.onShowModalAddEditModal();
              }
            },
          );
      }
    });
    $(`#${this.datatableSelector}`).on('click', 'button.btn-delete', function () {
      const id = Number($(this).data('id'));
      if (!Number.isNaN(id)) {
        const swalOptions = that.swalService.getSwalDeleteOptions({
          text: 'Le responsable sera supprimée de manière irréversible.',
        });
        Swal.fire(swalOptions).then((result) => {
          if (result.value) {
            that.managerService.delete(id)
              .pipe(takeUntil(that.isDestroyed$))
              .subscribe(
                (success) => {
                  this.swalService.showSwalSuccess(success);
                },
              );
          }
        });
      }
    });
  }

}
