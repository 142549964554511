import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AffairService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';

@Component({
  selector: 'app-affairs-index',
  templateUrl: './affairs-index.component.html',
  styleUrls: ['./affairs-index.component.scss'],
})
export class AffairsIndexComponent implements OnInit, AfterViewInit {

  public columns: DataTableColumn[] = [
    {
      attr: 'mlRef',
      name: 'Réf.',
      individualFilterInput: true,
    },
    {
      attr: 'finalCustomers',
      name: 'Nom(s) et prénom(s)',
      individualFilterInput: true,
    },
    {
      attr: 'customer',
      name: 'Client',
      orderable: false,
      searchable: false,
    },
    {
      attr: 'workSiteAddress',
      name: 'Adresse',
      individualFilterInput: true,
    },
    {
      attr: 'workSitePostalCode',
      name: 'CP',
      individualFilterInput: true,
    },
    {
      attr: 'workSiteLocality',
      name: 'Ville',
      individualFilterInput: true,
    },
    {
      attr: 'folderNumber',
      name: 'N° dossier',
      individualFilterInput: true,
      visible: false,
    },
    {
      attr: 'customerRef',
      name: 'Réf. client',
      individualFilterInput: true,
      visible: false,
    },
    {
      attr: 'mlStatuses',
      name: 'Statuts',
      orderable: false,
      searchable: false,
    },
  ];

  public datatableSelector = 'affairs-ml-datatable';

  constructor(
    private titleService: Title,
    private affairService: AffairService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des affaires');
  }

  ngAfterViewInit(): void {
    // this.affairService.initList(this.datatableSelector);
  }

}
