<div class="my-2 mx-3">
  <nav
    ngbNav
    #nav="ngbNav"
    class="nav-tabs justify-content-center my-3">
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="fas fa-user-tag"></i>
        <span>Fonctions</span>
      </a>
      <ng-template ngbNavContent>
        <app-professions-index></app-professions-index>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="fas fa-building"></i>
        <span>Types de marchés</span>
      </a>
      <ng-template ngbNavContent>
        <app-markets-types-index></app-markets-types-index>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="fas fa-store-alt"></i>
        <span>Types de clients</span>
      </a>
      <ng-template ngbNavContent>
        <app-customers-types-index></app-customers-types-index>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="fas fa-tag"></i>
        <span>Indicateurs clients</span>
      </a>
      <ng-template ngbNavContent>
        <app-indicators-customers-index></app-indicators-customers-index>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="tll fa-swords"></i>
        <span>Concurrents</span>
      </a>
      <ng-template ngbNavContent>
        <app-rivals-index></app-rivals-index>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="tll fa-businessman"></i>
        <span>Responsables</span>
      </a>
      <ng-template ngbNavContent>
        <app-managers-index></app-managers-index>
      </ng-template>
    </ng-container>
  </nav>
  <div
    [ngbNavOutlet]="nav"
    class="mt-2"></div>
</div>
