<div *nzModalTitle>
  {{ setting ? 'Édition' : 'Ajout' }} d'un paramètre de production
</div>
<form
  [formGroup]="form"
  autocomplete="off">
  <div class="form-group">
    <label class="required">Clé</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="key">
  </div>
  <div class="form-group">
    <label>Description</label>
    <textarea
      class="form-control input-sm"
      formControlName="description"></textarea>
  </div>
  <div class="form-group">
    <label class="required">Type de champ</label>
    <select
      class="form-control input-sm"
      formControlName="fieldType"
      id="fieldType">
      <option value="text">Texte</option>
      <option value="select">Select</option>
    </select>
  </div>
  <div class="form-group">
    <label>Classe</label>
    <ng-select
      [items]="availableEntitiesClasses"
      bindLabel="label"
      bindValue="value"
      [multiple]="false"
      formControlName="entityClass">
      <ng-template
        ng-label-tmp
        let-item="item">
        {{item.label}}
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm">
        {{item.label}}
      </ng-template>
    </ng-select>
  </div>
  <ng-container [ngSwitch]="fieldTypeValue">
    <label class="required">Valeur</label>
    <input
      *ngSwitchCase="'text'"
      type="text"
      class="form-control input-sm"
      formControlName="value">
    <ng-select
      *ngSwitchCase="'select'"
      [items]="items"
      bindLabel="label"
      bindValue="value"
      [multiple]="false"
      formControlName="value">
      <ng-template
        ng-label-tmp
        let-item="item">
        {{item.label}}
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm">
        {{item.label}}
      </ng-template>
    </ng-select>
    <input
      *ngSwitchDefault
      type="text"
      class="form-control input-sm"
      formControlName="value">
  </ng-container>
</form>
<div *nzModalFooter>
  <div class="modal-footer justify-content-center">
    <submit-button
      [isValidForm]="isValidForm()"
      [submitButton]="submitButton"
      (onButtonClicked)="submitForm()">
    </submit-button>
  </div>
</div>
