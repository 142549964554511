import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SelectModel, SubmitButton } from '@libs/models/src';
import { AffairService, NotifService, SubmitButtonService, UserService } from '@libs/services/src';
import { InterventionInskyService } from '@libs/services/src/lib/intervention-insky.service';

@Component({
  selector: 'app-affair-create-fake',
  templateUrl: './affair-create-fake.component.html',
  styleUrls: ['./affair-create-fake.component.scss'],
})
export class AffairCreateFakeComponent implements OnInit {

  public techniciens: SelectModel[];
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private submitButtonService: SubmitButtonService,
    private interventionInskyService: InterventionInskyService,
    private notifService: NotifService,
  ) { }

  ngOnInit(): void {
    this.form = this.getForm();
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      objectName: 'la commande',
      text: 'Exécuter',
      icon: faSave,
    });
    this.userService.getTechniciansSelect().subscribe(
      {
        next: (users) => {
          this.techniciens = users;

        },
      },
    );
  }

  isValidForm(): boolean {
    return this.form.valid;
  }

  onSubmit(): void {
    this.submitButtonService.setDisabled(this.submitButton);
    this.interventionInskyService.createFake(this.form).subscribe({
      next: (response) => {
        this.notifService.showSuccessNotif(response);
        this.submitButtonService.setEnabled(this.submitButton);
      },
    });

  }

  public getForm(): FormGroup {
    return this.formBuilder.group({
      userId: [null, Validators.required],
      number: [1, Validators.required],
    });
  }

}
