<div *nzModalTitle>
  <h4>
    {{ marketType ? 'Édition' : 'Ajout' }} d'un type de marché
  </h4>
</div>
<form
  [formGroup]="form"
  autocomplete="off">
  <div class="form-group">
    <label class="required">Nom</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="name">
  </div>
  <div class="form-group">
    <label class="required">Abréviation</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="abbreviation">
  </div>
  <div class="form-group">
    <label>Icône SVG</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="svgIcon">
  </div>
  <div class="form-group">
    <label>Icône TLL</label>
    <input
      type="text"
      class="form-control input-sm"
      formControlName="tllIcon">
  </div>
</form>
<div *nzModalFooter>
  <submit-button
    [isValidForm]="isValidForm()"
    [submitButton]="submitButton"
    (onButtonClicked)="submitForm()">
  </submit-button>
</div>
