<div class="my-2 mx-3">
  <nav
    ngbNav
    #nav="ngbNav"
    class="nav-tabs justify-content-center my-3">
    <ng-container ngbNavItem>
      <a
        ngbNavLink
        class="a-class">
        <i class="fas fa-cog"></i>
        <span>Paramètres</span>
      </a>
      <ng-template ngbNavContent>
        <app-settings-production-settings-index></app-settings-production-settings-index>
      </ng-template>
    </ng-container>
  </nav>
  <div
    [ngbNavOutlet]="nav"
    class="mt-2"></div>
</div>
