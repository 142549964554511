import { AnimateTimings } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Profession, SubmitButton } from '@libs/models/src';
import { ProfessionService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-professions-modal',
  templateUrl: './professions-modal.component.html',
  styleUrls: ['./professions-modal.component.scss'],
})
export class ProfessionsModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() profession: Profession;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private professionService: ProfessionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.professionService.setSubmitButton(this.submitButton);
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.profession) {
        this.professionService.update(this.profession.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      } else {
        this.professionService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.professionService.determinateSubmitButton(this.professionService, 'la fonction');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
