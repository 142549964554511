/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@libs/api/src';
import { ErosResponse, Intervention, InterventionInsky, InterventionNonconformity, Solution } from '@libs/models/src';
import { Observable } from 'rxjs';
import { FormFormatterService } from './utilities/form-formatter.service';

@Injectable({
  providedIn: 'root',
})
export class InterventionInskyService {

  public route = '/interventions/insky';

  constructor(
    private apiService: ApiService,
    private formFormatterService: FormFormatterService,
  ) {

  }

  public getNonconformities(interventionId: number): Observable<any> {
    return this.apiService.get(`${this.route}/${interventionId}/nonconformities`);
  }

  public sendSolution(
    interventionNonconformityId: number,
    selectedSolution: Solution,
    documentsCustomers: any[],
    documentsDelivered: any[],
    intervention: Intervention,
  ): Observable<ErosResponse> {
    const formData = this.buildFormData(selectedSolution, documentsCustomers, documentsDelivered, intervention);
    return this.apiService.importFile(`/interventions-nonconformities/${interventionNonconformityId}/send-solution`, formData);
  }

  public confirm(interventionNonconformityId: number): Observable<ErosResponse> {
    return this.apiService.get(`/interventions-nonconformities/${interventionNonconformityId}/confirm`);
  }

  public unconfirm(interventionNonconformityId: number, form: FormGroup): Observable<ErosResponse> {
    return this.apiService.post(`/interventions-nonconformities/${interventionNonconformityId}/unconfirm`, form.value);
  }

  public confirmAll(interventionId: number): Observable<ErosResponse> {
    return this.apiService.get(`/interventions/${interventionId}/confirm-all`);
  }

  public applyAll(interventionNonconformityId: number, selectedSolution: Solution, files: File[]): Observable<any> {
    const formData = this.buildFormData(selectedSolution, files, []);
    return this.apiService.importFile(`/interventions-nonconformities/${interventionNonconformityId}/apply-all`, formData);
  }

  public createFake(form: FormGroup): Observable<ErosResponse> {
    return this.apiService.post('/interventions/create-fake', this.formatForm(form));
  }

  public isSyncInsky(interventionId: number): Observable<boolean> {
    return this.apiService.get(`/interventions/insky/${interventionId}/is-sync-insky`);
  }

  private formatForm(form: FormGroup) {
    const values = this.formFormatterService.createFormCopy(form);
    values.userId = this.formFormatterService.formatSelectSingle(values.userId);
    return values;
  }

  private buildFormData(
    selectedSolution: Solution,
    documentsCustomers: any[],
    documentsDelivered: any[],
    intervention?: Intervention,
  ) {
    const formData = new FormData();
    formData.append('solution_id', selectedSolution.id.toString());
    if (intervention) {
      formData.append('intervention_id', intervention.id.toString());
    }
    documentsCustomers.forEach((files, index) => {
      let num = 0;
      files.forEach((file) => {
        formData.append(`documents_customers[${index}][${num}]`, file, file.name);
        num += 1;
      });
    });
    documentsDelivered.forEach((files, index) => {
      let num = 0;
      files.forEach((file) => {
        formData.append(`documents_delivered[${index}][${num}]`, file, file.name);
        num += 1;
      });
    });
    return formData;
  }

}
