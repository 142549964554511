<div class="my-2 mx-3">
  <pgcard
    [Refresh]="false"
    [Toggle]="false"
    [Close]="false"
    [Maximize]="false"
    [HeaderClass]="'card-header-primary'"
    [HeaderIcon]="'tll fa-mi-market'">
    <ng-template #CardTitle>Liste des affaires</ng-template>
    <div class="row mx-2 my-3">
      <app-table-datatable-toggle-vis [columns]="columns"></app-table-datatable-toggle-vis>
    </div>
    <table-datatable
      [selector]="datatableSelector"
      [columns]="columns">
    </table-datatable>
  </pgcard>
</div>
