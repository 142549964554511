import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrandService } from '@libs/services/src';
import { DataTableColumn } from '@libs/services/src/lib/classes/model-datatable';

@Component({
  selector: 'app-brands-index',
  templateUrl: './brands-index.component.html',
  styleUrls: ['./brands-index.component.scss'],
})
export class BrandsIndexComponent implements OnInit {

  public columns: DataTableColumn[] = [
    {
      attr: 'id',
      name: 'ID',
      individualFilterInput: true,
    },
    {
      attr: 'ref',
      name: 'Réf',
      individualFilterInput: true,
    },
    {
      attr: 'name',
      name: 'Nom',
      individualFilterInput: true,
    },
    {
      attr: 'agenciesNames',
      name: 'Agences',
      searchable: false,
      orderable: false,
    },
    {
      attr: 'idAbm',
      name: 'ABM',
      individualFilterInput: true,
    },
    {
      attr: 'idNrj',
      name: 'NRJ',
      individualFilterInput: true,
    },
    {
      attr: 'idPmb',
      name: 'PMB',
      individualFilterInput: true,
    },
    {
      attr: 'idAcc',
      name: 'ACC',
      individualFilterInput: true,
    },
    {
      attr: 'createdAtFormatted',
      searchColumn: 'createdAt',
      name: 'Créé le',
      searchable: false,
    },
    {
      attr: 'updatedAtFormatted',
      name: 'Mis à jour le',
      searchColumn: 'updatedAt',
      searchable: false,
    },
  ];

  public datatableSelector = 'brands-datatable';

  constructor(
    private titleService: Title,
    private brandService: BrandService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Liste des marques');
  }

  ngAfterViewInit(): void {
    this.brandService.initDataTable(this.datatableSelector, this.columns);
  }

}
