import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@libs/models/src';
import { MarketType } from '@libs/models/src/lib/market-type.model';
import { MarketTypeService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-markets-types-modal',
  templateUrl: './markets-types-modal.component.html',
  styleUrls: ['./markets-types-modal.component.scss'],
})
export class MarketsTypesModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() form: FormGroup;
  @Input() marketType: MarketType;
  public submitButton: SubmitButton;

  constructor(
    private modal: NzModalRef,
    private marketTypeService: MarketTypeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.determinateSubmitButton();
    this.marketTypeService.setSubmitButton(this.submitButton);
  }

  submitForm(): void {
    if (this.isValidForm()) {
      if (this.marketType) {
        this.marketTypeService.update(this.marketType.id, this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      } else {
        this.marketTypeService.store(this.form)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(() => {
            this.modal.close();
          });
      }
    }
  }

  private determinateSubmitButton() {
    this.submitButton = this.marketTypeService.determinateSubmitButton(this.marketType, 'le type de marché');
  }

  public isValidForm(): boolean {
    return this.form.valid;
  }

}
