<ng-container *ngIf="affair.consteoId; else withoutConsteoMails">
  <pg-tabset
    tabAnimation="slide-left"
    Type="simple"
    TabPosition="left">
    <pg-tab>
      <ng-template #TabHeading>{{appkey}}</ng-template>
      <nz-table
        #tableData
        nzShowPagination
        [nzData]="mailsData$|async">
        <thead>
          <tr>
            <th>Envoyé le</th>
            <th>Par</th>
            <th>Sujet</th>
            <th>Corps de texte</th>
            <th>Destinataires</th>
            <th>Destinataires CC</th>
            <th>Destinataires CCI</th>
            <th [nzWidth]="'400px'">Pièces jointes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableData.data">
            <td>{{ data.createdAtFormatted }}</td>
            <td>
              {{ data.fromEmail }}
              <p *ngIf="data.sendAsEmail">Via : {{ data.sendAsEmail }}</p>
            </td>
            <td>{{ data.subject }}</td>
            <td>
              <div [innerHTML]="data.content"></div>
            </td>
            <td>{{ data.recipients }}</td>
            <td>{{ data.ccRecipients }}</td>
            <td>{{ data.bccRecipients }}</td>
            <td>
              <ng-container *ngIf="data.attachments?.length > 0">
                <div
                  *ngFor="let attachment of data.attachments"
                  class="py-1">
                  <span class="label label-default">{{ extractLastPartOfPath(attachment) }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="data.files?.length > 0">
                <div
                  *ngFor="let file of data.files"
                  class="py-1">
                  <span class="label label-default">{{ file }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="data.files?.length === 0 && data.attachments?.length === 0">Aucune pièce jointe</ng-container>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </pg-tab>
    <pg-tab>
      <ng-template #TabHeading>Constéo</ng-template>
      <nz-table
        #tableDataConsteo
        nzShowPagination
        [nzData]="consteoMailsData$ | async">
        <thead>
          <tr>
            <th>Envoyé le</th>
            <th>Par</th>
            <th>Sujet</th>
            <th>Corps de texte</th>
            <th>Destinataires</th>
            <th>Destinataires CC</th>
            <th>Destinataires CCI</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableDataConsteo.data">
            <td>{{ data.createdAtFormatted }}</td>
            <td>
              {{ data.fromEmail }}
              <p *ngIf="data.sendAsEmail">Via : {{ data.sendAsEmail }}</p>
            </td>
            <td>{{ data.subject }}</td>
            <td>
              <div [innerHTML]="data.content"></div>
            </td>
            <td>{{ data.recipients }}</td>
            <td>{{ data.ccRecipients }}</td>
            <td>{{ data.bccRecipients }}</td>
          </tr>
        </tbody>
      </nz-table>
    </pg-tab>

  </pg-tabset>
</ng-container>
<ng-template #withoutConsteoMails>
  <div class="my-2 mx-3">
    <nz-table
      #tableDataSingle
      nzShowPagination
      [nzData]="mailsData$|async">
      <thead>
        <tr>
          <th>Envoyé le</th>
          <th>Par</th>
          <th>Sujet</th>
          <th>Corps de texte</th>
          <th>Destinataires</th>
          <th>Destinataires CC</th>
          <th>Destinataires CCI</th>
          <th [nzWidth]="'400px'">Pièces jointes</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of tableDataSingle.data">
          <td>{{ data.createdAtFormatted }}</td>
          <td>
            {{ data.fromEmail }}
            <p *ngIf="data.sendAsEmail">Via : {{ data.sendAsEmail }}</p>
          </td>
          <td>{{ data.subject }}</td>
          <td>
            <div [innerHTML]="data.content"></div>
          </td>
          <td>{{ data.recipients }}</td>
          <td>{{ data.ccRecipients }}</td>
          <td>{{ data.bccRecipients }}</td>
          <td>
            <ng-container *ngIf="data.attachments?.length > 0">
              <div
                *ngFor="let attachment of data.attachments"
                class="py-1">
                <span class="label label-default">{{ extractLastPartOfPath(attachment) }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="data.files?.length > 0">
              <div
                *ngFor="let file of data.files"
                class="py-1">
                <span class="label label-default">{{ file }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="data.files?.length === 0 && data.attachments?.length === 0">Aucune pièce jointe</ng-container>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
