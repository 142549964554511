import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Agency } from '@libs/models/src';
import { AgencyService, NotifService, UnsubscribeOnDestroy } from '@libs/services/src';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { RandomTool } from '_tools/random';

@Component({
  selector: 'eros-front-agency-consteo-token',
  templateUrl: './agency-consteo-token.component.html',
  styleUrls: ['./agency-consteo-token.component.scss'],
})
export class AgencyConsteoTokenComponent extends UnsubscribeOnDestroy implements OnInit {

  @Input() agency: Agency;
  public form: FormGroup;
  public isDisabled = false;

  constructor(
    private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private agencyService: AgencyService,
    private notifService: NotifService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.agency) {
      this.form = this.initForm();

    }
  }

  initForm(): FormGroup {
    return this.formBuilder.group({
      consteoToken: [this.agency?.consteoToken],
    });

  }

  onSaveClicked(): void {
    this.isDisabled = true;
    this.agencyService.updateConsteoToken(this.agency.id, this.form)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(
        (success) => {
          this.notifService.showSuccessNotif(success);
          this.modal.close();
        },
      );

  }

  generateRandomToken(): void {
    const r = RandomTool.randomString(40);
    this.form.patchValue({
      consteoToken: r,
    });
  }

}
