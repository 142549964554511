import { Router } from '@angular/router';
import { pagesToggleService } from '@pages/services/toggler.service';
import { RootLayout } from '@pages/layouts/root/root.component';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomLocalStorageService } from '@libs/services/src/lib/utilities/custom-local-storage.service';
import { AuthentificationService } from '../../../../../../libs/auth/src/lib/authentification.service';

@Component({
  selector: 'eros-front-app-with-sidebar',
  templateUrl: './app-with-sidebar.component.html',
  styleUrls: ['./app-with-sidebar.component.scss'],
})
export class AppWithSidebarComponent extends RootLayout implements OnInit {

  menuLinks = [];
  public userInfos;
  public roles = [];

  constructor(
    _toggler: pagesToggleService,
    _router: Router,
    public localStorageService: CustomLocalStorageService,
    private authService: AuthentificationService,
  ) {
    super(_toggler, _router, localStorageService);
  }

  ngOnInit() {
    this.userInfos = this.authService.getUserInfos();
    this.initMenuLinks();
    this.roles = this.authService.getRoles();
  }

  logout() {
    this.authService.logout();
  }

  isAdministrator() {
    if (this.roles) {
      return this.roles.includes('administrator');
    }
    return false;
  }

  initMenuLinks() {
    this.menuLinks = [
      {
        label: 'Accueil',
        routerLink: '/',
        iconType: 'fa',
        isShow: true,
        iconName: 'th-large',
      },
      {
        label: 'Admin',
        iconType: 'fa',
        iconName: 'lock',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Gestion des utilisateurs',
            routerLink: '/admin/users/index',
            iconType: 'letter',
            iconName: 'gu',
          },
          {
            label: 'Gestion des rôles',
            routerLink: '/admin/roles/index',
            iconType: 'letter',
            iconName: 'gr',
          },
          {
            label: 'Gestion des permissions',
            routerLink: '/admin/permissions/index',
            iconType: 'letter',
            iconName: 'gp',
          },
        ],
      },
      {
        label: 'Affaires',
        routerLink: '/affairs/index',
        iconType: 'fa',
        iconName: 'suitcase',
        isShow: true,
      },
      {
        label: 'Base client',
        iconType: 'fa',
        iconName: 'user',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Groupes',
            routerLink: '/groups/index',
            iconType: 'letter',
            iconName: 'gr',
          },
          {
            label: 'Marques',
            routerLink: '/brands/index',
            iconType: 'letter',
            iconName: 'ma',
          },
          {
            label: 'Agences',
            routerLink: '/agencies/index',
            iconType: 'letter',
            iconName: 'ag',
          },
          {
            label: 'Contacts',
            routerLink: '/contacts/index',
            iconType: 'letter',
            iconName: 'co',
          },
        ],
      },
      {
        label: 'Paramètres',
        iconType: 'fa',
        iconName: 'cog',
        toggle: 'close',
        isShow: true,
        submenu: [
          {
            label: 'Affaires',
            routerLink: '/settings/affairs',
            iconType: 'letter',
            iconName: 'af',
          },
          {
            label: 'Client',
            routerLink: '/settings/customer',
            iconType: 'letter',
            iconName: 'cl',
          },
          {
            label: 'Production',
            routerLink: '/settings/production',
            iconType: 'letter',
            iconName: 'pr',
          },
        ],
      },
    ];
  }

}
